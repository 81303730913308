import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import Tibbletrafikskola from '../../images/ovrigt/tibble.png'
import Ellstroms from '../../images/ovrigt/ellstroms.png'
import Gillinge from '../../images/ovrigt/gillinge.png'
const Samarbetspartners = () => (
  <Layout>
    <Seo title='Samarbetspartners' />
    <PageHeader text='Samarbetspartners' />
    <div className='container'>
      <div className='row justify-content-around'>

        <div className='col-12 col-sm-12 col-md-5 col-lg-2 col-xl-4 p-0  text-center'>
          <img src={Tibbletrafikskola} alt='Tibble trafikskola' className='img-fluid rounded' />
          <h4 className='mt-0 pt-0'><a href='http://www.tibbletrafikskola.se/' className='btn btn-primary' target='_Blank' rel='noreferrer'>Tibble trafikskola</a></h4>
        </div>

        <div className='col-12 col-sm-12 col-md-5 col-lg-2 col-xl-4 p-0  text-center'>
          <img src={Ellstroms} alt='Ellströms trafikskola' className='img-fluid rounded' />
          <h4 className='mt-0 pt-0'><a href='https://ellstroms.se/' className='btn btn-danger' target='_Blank' rel='noreferrer'>Ellströms trafikskola</a></h4>
        </div>

        <div className='col-12 col-sm-12 col-md-5 col-lg-2 col-xl-4 p-0  text-center'>
          <img src={Gillinge} alt='Trafiksäkerhetscenter' className='img-fluid rounded' />
          <h4 className='mt-0 pt-0'><a href='https://gillinge.se/' className='btn btn-danger' target='_Blank' rel='noreferrer'>Gillingebanan</a></h4>
        </div>

      </div>
    </div>
  </Layout>
)

export default Samarbetspartners
